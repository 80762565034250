.admin-menu-button {
  background-color: #ff0015;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.admin-menu-button:hover {
  background-color: #a62020;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-menu-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.admin-menu-button .admin-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
} 