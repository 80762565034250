/* Admin Styles */
.admin-results-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
}

.admin-content {
  flex: 1;
  padding: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-loading,
.admin-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
}

.admin-error {
  color: #dc3545;
}

.stats-badge {
  font-size: 14px;
  margin-right: 10px;
  padding: 8px 12px;
}

.survey-stats {
  margin-top: 15px;
}

.user-list {
  max-height: 500px;
  overflow-y: auto;
}

.selected-user {
  background-color: #e6f7ff !important;
}

.no-user-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #f8f9fa;
  border-radius: 5px;
  color: #6c757d;
}

.question-result {
  padding-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
}

.chart-container {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .admin-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .admin-header button {
    margin-top: 10px;
  }
}

/* Survey Respondents Styles */
.respondent-table {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.respondent-table thead th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  padding: 12px 15px;
  font-weight: 600;
  color: #495057;
}

.respondent-table tbody tr:hover {
  background-color: #f1f8ff;
}

.respondent-table td {
  vertical-align: middle;
  padding: 12px 15px;
}

/* User profile styling */
.user-avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  border: 2px solid #eee;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.user-info {
  display: flex;
  align-items: center;
}

/* User info styling */
.user-info {
  text-align: center;
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 500px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-info h2 {
  margin-bottom: 1rem;
  color: #333;
}

.user-info p {
  margin-bottom: 0.75rem;
  color: #555;
}

.user-name {
  font-weight: 500;
  color: #333;
}

/* Progress bar styling */
.completion-bar {
  width: 100%;
  height: 12px;
  border-radius: 10px;
  overflow: hidden;
}

.completion-bar .progress-bar {
  height: 100%;
  transition: all 0.5s ease;
}

.completion-stats {
  margin-left: 8px;
  font-size: 0.85rem;
  color: #6c757d;
  white-space: nowrap;
}

.completion-wrapper {
  display: flex;
  align-items: center;
  min-width: 200px;
}

/* Status badges */
.status-badge {
  padding: 6px 10px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.badge-completed {
  background-color: #28a745;
  color: white; /* Text is white on green background */
}

.badge-inprogress {
  background-color: #ffc107;
  color: black; /* Ensuring text is black on yellow background */
}

.badge-stats {
  margin-left: 6px;
  font-size: 0.8rem;
  text-transform: none;
  font-weight: normal;
}

/* Action buttons styling */
.action-buttons {
  display: flex;
  gap: 8px;
}

.view-btn {
  background-color: #007bff;
  border-color: #007bff;
}

.view-btn:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

.delete-btn {
  background-color: #dc3545;
  border-color: #dc3545;
}

.delete-btn:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

/* Survey info card styling */
.survey-info-card {
  margin-bottom: 24px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.survey-info-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #eaeaea;
  padding: 15px 20px;
}

.survey-info-card h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #212529;
}

.survey-info-body {
  padding: 20px;
}

.survey-description {
  margin-bottom: 16px;
  color: #495057;
}

.respondent-count {
  font-size: 0.9rem;
  color: #6c757d;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .completion-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .completion-stats {
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .action-buttons {
    flex-direction: column;
  }

  .respondent-table td {
    padding: 10px 8px;
  }
}

/* Empty state styling */
.empty-state {
  text-align: center;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #6c757d;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
}

.empty-state:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.empty-state i {
  font-size: 48px;
  margin-bottom: 16px;
  color: #adb5bd;
  display: block;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.empty-state h4 {
  font-weight: 600;
  margin-bottom: 10px;
  color: #495057;
}

.empty-state p {
  color: #6c757d;
  max-width: 400px;
  margin: 0 auto;
}

/* Making sure text is visible on colored backgrounds */
.badge-inprogress,
.survey-description,
.respondent-count,
.empty-state,
.user-name {
  color: #212529; /* Ensuring text is dark */
}

/* Small text styling for email and date cells */
.small-text-cell {
  color: #212529;
  font-size: 0.9rem;
  font-weight: normal;
}

/* Main heading styling */
.page-main-heading {
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 8px;
  text-transform: capitalize;
}

.page-main-heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #007bff;
  border-radius: 2px;
}
