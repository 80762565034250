.result-admin-survey-main {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);

  min-height: 92vh;
  margin-top: 40px;
  margin-bottom: 20px;

  color: #333;
}

.result-admin-survey-top {
  width: 99%;

  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  overflow-x: auto;
  border-radius: 10px;
  padding-top: 50px;
}

.result-admin-survey-ser {
  margin-top: 10px;
  width: 98%;
  /*border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);*/
  margin-bottom: 10px;
}

.result-admin-survey-main h3 {
  color: #333;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 99%;
}

/* Main container styles */
.survey-result-container {
  width: 100%;
  margin-bottom: 30px;
}

/* Style for the affinity banner */
.affinity-banner {
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 15px;
  margin: 20px 0;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.affinity-banner h3 {
  margin: 0;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.affinity-label {
  font-weight: bold;
  color: #333;
}

.affinity-value {
  color: #6a0dad; /* Purple for emotional affinity */
  font-weight: bold;
}

.affinity-share {
  color: #555;
  font-style: italic;
  font-size: 1.3rem;
}

/* High affinity styling */
.high-affinity {
  color: #ff0000 !important; /* Red color for high affinity */
  font-weight: bold;
}

/* Question comparison boxes */
.questions-comparison {
  display: flex;
  gap: 20px;
  width: 100%;
}

.question-box {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  min-height: 150px;
}

.left-question {
  background-color: #c0d9ff; /* Light pink for left question box */
  box-shadow: 0px 0px 15px rgba(126, 2, 103, 0.15);
}

.right-question {
  background-color: #a2c6fb; /* Light purple/blue for right question box */
  box-shadow: 0px 0px 15px rgba(2, 10, 126, 0.15);
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .questions-comparison {
    flex-direction: column;
  }

  .affinity-banner h3 {
    flex-direction: column;
    gap: 10px;
  }
}
