/* resultsSurvey.css */

/* Additional styles to match admin version */
.result-admin-survey-main {
  max-width: 1200px;
  margin: 80px auto 40px auto;
  padding: 25px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  color: #333;
}

.result-admin-survey-top {
  margin-bottom: 30px;
  text-align: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}

.result-admin-survey-top h1 {
  color: #2c3e50;
  margin-bottom: 15px;
}

.result-admin-survey-top h2 {
  color: #3498db;
  margin-bottom: 10px;
}

.result-admin-survey-ser {
  margin-top: 20px;
}

.result-survey-main {
  max-width: 600px;
  width: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);

  min-height: 92vh;
  margin-top: 40px;
  margin-bottom: 20px;

  color: #333;
}

.result-survey-top {
  max-width: 580px;
  width: 580px;

  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  overflow-x: auto;
  border-radius: 10px;
  padding-top: 50px;
}

.result-survey-ser {
  margin-top: -10px;
  max-width: 580px;
  width: 580px;
  /*border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);*/
  margin-bottom: 10px;
}

.result-survey-ser li {
  list-style: none;
}

.result-survey-ser ul {
  margin-left: -30px;
}

.result-survey-ser-unit {
  max-width: 550px;
  width: 550px;

  font-size: 20px;
  /*background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(126, 2, 103, 0.15), rgba(255, 255, 255, 1));*/
  overflow-x: auto;
  /*text-align: left;*/
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #e0e0e0;
  margin-left: 15px;
  margin-top: 10px;

  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(126, 2, 103, 0.3);
}

.question-image {
  max-width: 100%; /* This ensures the image is not bigger than its container */
  max-height: 400px; /* Set a max-height that suits your design */
  border: 2px solid #000; /* Example border, change as you like */
  border-radius: 4px; /* Optional: if you want rounded corners */
  object-fit: cover; /* This makes sure that the aspect ratio of the image is maintained */
}

.result-survey-diagram {
  max-width: 580px;
  width: 580px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);

  margin-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.result-survey-main h3 {
  color: #333;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 92%;
}

.result-question-unit {
  color: #333;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.survey-result-container {
  margin-bottom: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.affinity-banner {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 10px 15px;
  margin: 15px 0;
  border-left: 4px solid #3498db;
}

.affinity-banner h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 18px;
  width: auto;
  background-color: transparent;
}

.affinity-banner.centered-share h3 {
  justify-content: center;
}

.centered-share .affinity-share {
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: #2c3e50;
  font-weight: 500;
}

.affinity-label {
  font-weight: bold;
  color: #3498db;
}

.affinity-value {
  color: #2c3e50;
}

.affinity-share {
  font-size: 14px;
  color: #7f8c8d;
}

.questions-comparison {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.question-box {
  flex: 1;
  padding: 15px;
  border-radius: 6px;
  /* Removed background-color to keep it white */
}

.left-question {
  border-left: 3px solid #3498db;
}

.right-question {
  border-left: 3px solid #9b59b6;
}

h2 {
  font-size: 20px;
  color: #2c3e50;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.mb-4 {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .affinity-banner h3 {
    flex-direction: column;
    align-items: flex-start;
  }

  .affinity-share {
    margin-top: 5px;
  }

  .questions-comparison {
    flex-direction: column;
  }
}

/* Add class for high affinity highlight */
.high-affinity {
  color: #e74c3c; /* Red color to stand out */
  font-weight: bold;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
