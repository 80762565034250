/********************* BOT PANEL **********************/
.footer {
  background-color: #347ae2;
  color: #fff;
  padding: 5px;
  width: 100%;

  text-align: left;
  padding-left: 20px;
  position: relative;

  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}

.footer_fixed {
  background-color: #1768e2;
  color: #fff;
  padding: 5px;
  width: 100%;

  text-align: left;
  padding-left: 20px;

  bottom: 0;
  left: 0;
  position: fixed;

  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}
/*******************************************/

.base-unfixed-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 100vh;
}
.base-unfixed-footer-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
