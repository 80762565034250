/******************** TOP PANEL***********************/
.top_panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #347ae2; /* #333;*/

  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}

.navbar-fixed {
  top: 0;
  left: 0;
  background-color: #347ae2;
  color: #fff;
  padding: 15px;
  font-size: 32px; /* Збільшено розмір шрифту */
  font-weight: bold; /* Додає жирність тексту */
  /* box-shadow: 0px 2px 10px rgba(126, 2, 103, 0.689); */
  cursor: pointer;
}

.navbar-fixed ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  right: 10%;
}

.navbar-fixed li {
  display: inline;
  margin-right: 10px;
}

.navbar-fixed a {
  text-decoration: none;
  color: #fff;
}

.navbar-fixed a:hover {
  color: #ccc;
}

.nav-center-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

/*******************************************/
.language-selector {
  position: fixed;
  right: 7%;
  top: 12px;
  background-color: #2050a6;
  border-radius: 5px;
}

.language-selector button {
  margin-left: 10px;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

.language-selector button.active {
  font-weight: bold;
}

.language-selector select {
  padding: 5px;
  font-size: 16px;
  border: none;
  background-color: #2050a6; /* Колір фону */
  color: #fff; /* Колір тексту */
  appearance: none; /* Відключаємо браузерний стиль комбінованого списку */
  transition: background-color 0.3s, color 0.3s;

  border-radius: 5px;
}

.language-selector select:focus {
  background-color: #333; /* Змінюємо колір фону при фокусі */
  outline: none; /* Видаляємо обведення при фокусі */
}

/* Підсвітка при наведенні */
.language-selector select:hover {
  color: #ccc;
}
