.admin-panel-page {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 15px;
  font-family: Arial, sans-serif;
}

/* Top Section */
.admin-panel-top {
  text-align: center;
  margin-bottom: 20px;
  background-color: #1e293b;
  padding: 15px;
  border-radius: 8px;
  color: white;
}

.admin-panel-top h1 {
  color: #3b82f6;
  margin: 0 0 5px 0;
  font-size: 28px;
}

.admin-panel-top p {
  margin: 5px 0 15px 0;
  font-size: 16px;
  opacity: 0.9;
}

/* Navigation Tabs */
.admin-tabs {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.admin-tab-button {
  background-color: #2563eb;
  color: white;
  padding: 8px 16px;
  margin: 0 8px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s;
  font-weight: 500;
}

.admin-tab-button:hover {
  background-color: #1d4ed8;
  transform: translateY(-2px);
}

.admin-tab-button.active {
  background-color: #103087;
  box-shadow: 0 2px 5px rgba(126, 34, 206, 0.4);
}

/* Search Input */
.admin-search {
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.admin-search-input {
  width: 100%;
  max-width: 450px;
  padding: 8px 12px;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.2s;
}

.admin-search-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  outline: none;
}

/* Loading State */
.admin-loading {
  text-align: center;
  padding: 30px;
  font-size: 16px;
  color: #6b7280;
}

/* Content Containers */
.admin-content {
  margin-top: 15px;
}

.admin-surveys, .admin-users {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.admin-surveys h2, .admin-users h2 {
  color: #1e293b;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 18px;
}

.no-data-message {
  text-align: center;
  padding: 20px;
  color: #6b7280;
  font-style: italic;
  font-size: 15px;
}

/* List Styles */
.admin-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: #f9fafb;
  transition: all 0.2s;
}

.admin-list-item:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  background-color: #f3f4f6;
}

.admin-item-details {
  flex: 1;
}

.admin-item-details h3 {
  margin: 0 0 6px 0;
  color: #2563eb;
  font-size: 16px;
}

.admin-item-details p {
  margin: 3px 0;
  color: #4b5563;
  font-size: 14px;
}

/* Action Buttons */
.admin-item-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.admin-action-button {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background-color: #f3f4f6;
  color: #1f2937;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.admin-action-button:hover {
  background-color: #e5e7eb;
}

.admin-action-button.delete {
  background-color: #fee2e2;
  color: #b91c1c;
}

.admin-action-button.delete:hover {
  background-color: #fecaca;
}

.admin-icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

/* Back Button */
.admin-back-button {
  display: inline-flex;
  align-items: center;
  background-color: #f3f4f6;
  color: #1f2937;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  float: left;
}

.admin-back-button:hover {
  background-color: #e5e7eb;
}

/* User Info Box */
.user-info {
  margin: 15px 0;
  padding: 12px;
  background-color: #f3f4f6;
  border-radius: 6px;
  text-align: center;
}

.user-info h2 {
  color: #2563eb;
  margin: 0 0 8px 0;
  font-size: 18px;
}

.user-info p {
  margin: 5px 0;
  color: #4b5563;
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .admin-list-item {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .admin-item-details h3 {
    font-size: 15px;
  }
  
  .admin-item-details p {
    font-size: 13px;
  }
  
  .admin-item-actions {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-start;
  }

  .admin-panel-top h1 {
    font-size: 24px;
  }
  
  .admin-action-button {
    margin: 5px 5px 0 0;
    font-size: 12px;
    padding: 5px 8px;
  }
  
  .admin-search-input {
    font-size: 14px;
  }
}