/*  */
.survey-users-base {
  max-width: 600px;
  width: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);

  /*min-height: 10vh;*/
  /*margin-top: 40px;*/
  margin-bottom: 20px;
  min-height: 92vh;

  color: #333;
}

.survey-users-top {
  max-width: 580px;
  width: 580px;

  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  overflow-x: auto;
  border-radius: 10px;
  padding-top: 50px;
}

.survey-users-mid {
  margin-top: 10px;
  max-width: 580px;
  width: 580px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  margin-bottom: 10px;
  text-align: left;
}

.survey-users-mid li {
  list-style: none;
}

.icon-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(126, 2, 103, 0.15),
    rgba(255, 255, 255, 1)
  );
  margin-left: -10px;
}

.icon-btn-container p {
  flex-grow: 1;
  margin-left: -10px;
}

.iconed-button {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #347ae2;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.iconed-button:hover {
  background-color: #2050a6;
}

.icon {
  width: 20px;
  height: 20px;
}
