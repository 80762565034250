/* HelpPage.css */
.HelpPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.help-container {
  flex: 1;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.help-container h1 {
  color: #2c3e50;
  font-size: 28px;
  margin-bottom: 20px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  text-align: center;
}

.help-section {
  margin-bottom: 30px;
}

.help-section h2 {
  color: #2c3e50;
  font-size: 22px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.help-item {
  margin-bottom: 20px;
  padding-left: 10px;
  text-align: left;
}

.help-item h3 {
  color: #3498db;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
}

.help-item p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: left;
}

.help-item ol,
.help-item ul {
  padding-left: 25px;
  margin-bottom: 15px;
  text-align: left;
}

.help-item li {
  color: #333;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.4;
  text-align: left;
}

.help-contact {
  font-weight: bold;
  color: #3498db;
  font-size: 16px;
  margin: 10px 0;
  text-align: left;
}

.help-back-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
  display: block;
  margin: 30px auto;
}

.help-back-button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .help-container {
    padding: 15px;
    margin: 15px;
  }

  .help-container h1 {
    font-size: 24px;
  }

  .help-section h2 {
    font-size: 20px;
  }

  .help-item h3 {
    font-size: 16px;
  }

  .help-item p,
  .help-item li {
    font-size: 14px;
  }

  .help-back-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
