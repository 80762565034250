.take-survey-page {
  width: 400px;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: #333;
}

.take-survey-page form {
  display: flex;
  flex-direction: column;
}

.take-survey-page h1 {
  font-size: 35px;
}

.take-survey-page label {
  margin-bottom: 5px;
}

.take-survey-page input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.take-survey-page button {
  padding: 10px;
  background-color: #347ae2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.take-survey-page button:hover {
  background-color: #2050a6;
}

.take-survey-page button:active {
  background-color: #860a94;
  box-shadow: 0 5px #720a7d;
  transform: translateY(2px);
}
