/* base page bg */
.my-survey-page {
  max-width: 450px;
  width: 450px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);

  min-height: 92vh;
  margin-top: 40px;
  margin-bottom: 20px;

  color: #333;
}

/* top side with buttons for switching surveys */
.my-survey-top {
  margin-top: 25px;
  max-width: 430px;
  width: 420px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);

  padding-bottom: 10px;
}

/* surveys by types */
.my-survey-all {
  margin-top: 25px;
  max-width: 430px;
  width: 420px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);

  padding-bottom: 10px;
}

.my-survey-all ul {
  margin-left: -30px;
}

.my-survey-all li {
  list-style: none;
}

.my-survey-all-unit {
  width: 400px;
  border-radius: 0px;
  /*box-shadow: 0px 0px 10px rgba(126, 2, 103, 0.2);*/

  margin-bottom: 10px;
  /*border-bottom: 2px solid rgba(126, 2, 103, 0.2);*/ /* Додає розділяючу лінію */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(126, 2, 103, 0.15),
    rgba(255, 255, 255, 1)
  ); /* Градієнтна лінія */
}

.my-survey-all-unit p {
  overflow-x: auto;
  /*white-space: nowrap;
    text-overflow:ellipsis;*/
}

.my-survey-all-unit-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  /*box-shadow: 0px 0px 20px rgba(0,0,0,1);*/
}

.base-iconed-button {
  display: flex;
  align-items: center;
  background-color: #347ae2;
  color: white;
  border: none;
  padding: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.base-iconed-button:hover {
  background-color: #2050a6;
}

.icon {
  fill: white;
  max-width: 20px;
  max-height: 20px;
  margin-left: 10px;
}
