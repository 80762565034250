.hash-page {
  width: 500px;
  max-width: 500px;
  margin: auto;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: #333;

  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hash-page-main {
  width: 99%;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  border-radius: 10px;
  overflow-x: auto;
}

.hash-page_main_text {
  color: #333;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 95%;
}

.base-button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  padding-bottom: -20px;
  /*box-shadow: 0px 0px 20px rgba(0,0,0,1);*/
}

.hash-page-unit {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(126, 2, 103, 0.15),
    rgba(255, 255, 255, 1)
  );
}
