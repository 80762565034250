.about-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #333; /* Ensure text is dark and readable */
  text-align: left;
}

.about-container h1 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
}

.about-title {
  color: red; /* Ensure the title is red */
  font-size: 36px;
  margin-bottom: 20px;
}

.about-intro {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 20px;
}

.about-image {
  flex: 1;
  text-align: center;
}

.about-image img {
  max-width: 100%;
  border-radius: 8px;
}

.about-text {
  flex: 1;
  color: #333; /* Ensure paragraph text is readable */
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.about-highlight {
  font-weight: bold;
  text-align: center;
}

.highlight {
  color: red;
}

.about-section {
  margin-bottom: 40px;
}

.about-section h2 {
  color: #2a5298;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 24px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.feature-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  display: block;
  height: 50px;
  width: 50px;
  margin: 0 auto 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.feature-item h3 {
  color: #333;
  margin-bottom: 10px;
}

.process-steps {
  counter-reset: step-counter;
  padding-left: 0;
  list-style-type: none;
}

.process-steps li {
  position: relative;
  padding: 15px 0 15px 50px;
  margin-bottom: 10px;
}

.process-steps li:before {
  content: counter(step-counter);
  counter-increment: step-counter;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2a5298;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}

.contact-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.contact-option {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.contact-icon {
  margin-right: 15px;
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .about-intro {
    flex-direction: column;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .contact-options {
    grid-template-columns: 1fr;
  }
}
