/* LoginPage.css */
.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 92vh;
  background-color: #e8f1f9;
  font-family: "Arial", sans-serif;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}
.LoginPage h1 {
  color: #333;
  margin-bottom: 10px;
}
.LoginPage p {
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
}
/* Assuming GoogleAuthButton inherits these styles */
button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4285f4; /* Google blue */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
button:hover {
  background-color: #357ae8;
}
button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.5);
}
/******************** Login form ***********************/
/* Стилі для контейнера форми */
.login-container {
  width: 475px;
  margin: 0 auto;
}
/* Стилі для самої форми */
.login-form {
  background-color: #fffdfd;
  padding: 20px;
  /*border-radius: 5px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}
/* Стилі для заголовка форми */
.login-form h2 {
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}
/* Стилі для полів введення */
.login-form input[type="text"],
.login-form input[type="email"],
.login-form input[type="password"] {
  width: calc(100% - 22px); /*віднімаємо ширину внутрішнього відступу */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #fffdfd;
  border-radius: 5px;
  display: inline-block; /* розміщуємо поля поруч */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-form input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  outline: none;
  border-color: #347ae2;
}
/* кнопка підтвердження логіну/реєстрації*/
.login-submitbtn {
  width: 100%;
  padding: 10px;
  background-color: #347ae2; /*#347ae2;*/
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}
.login-submitbtn:hover {
  background-color: #2050a6;
  box-shadow: 0px 0px 10px rgba(139, 27, 151, 0.1);
}
.login-submitbtn:focus {
  background-color: #0a3a94;
  box-shadow: none;
}
/* кнопка "забули пароль" */
.forgot-password-container {
  text-align: right;
  margin-top: -10px; /* налаштуйте за потреби */
  margin-bottom: 15px; /* відступ від нижнього поля вводу */
}
.login-forgot-password {
  background-color: #fff;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  /*text-decoration: underline;*/
}
.login-forgot-password:hover {
  background-color: #2050a6;
  color: #fff;
}
.login-forgot-password:focus {
  background-color: #2050a6;
  color: #fff;
  box-shadow: none;
}
/* світчер логін-реєстарція*/
.login-register-swbtn {
  width: 75%;
  padding: 10px;
  background-color: #347ae2;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  margin-bottom: 15px;
}
.login-register-swbtn:hover {
  background-color: #2050a6; /* #2050a6*/
  box-shadow: 0px 0px 10px rgba(139, 27, 151, 0.1);
}
.login-register-swbtn:focus {
  background-color: #0a3994; /* #860a94; */
  box-shadow: none;
}

/* Help button styles */
.login-help-btn {
  width: 50%;
  padding: 8px 15px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 5px;
  font-size: 14px;
}

.login-help-btn:hover {
  background-color: #45a049;
  box-shadow: 0px 0px 10px rgba(76, 175, 80, 0.3);
}

.login-help-btn:focus {
  background-color: #3d8b40;
  box-shadow: none;
}

/* google login text style*/
.login-google-text {
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
  color: black;
}
/******************** GENERAL PAGE ***********************/
body,
html {
  overflow-x: hidden;
}
/********************* BOT PANEL **********************/
.footer {
  background-color: #333;
  color: #fff;
  padding: 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
}

.about-btn {
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 23px;
  margin: 3px;
  transition: all 0.2s;
  border-radius: 4px;
}

.about-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

.back-btn {
  font-size: 25px;

  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
  transition: all 0.2s;
  border-radius: 4px;
}

.back-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

.about-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-section {
  margin-bottom: 30px;
}

.about-section h2 {
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.about-section ul {
  padding-left: 20px;
}

.about-section li {
  margin-bottom: 8px;
}
