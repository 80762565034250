.forgot-pssw-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 92vh;
  background-color: #f5f5f5;
  font-family: "Arial", sans-serif;
  background-color: rgb(255, 255, 255);

  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
  color: #333;

  width: 475px;
  max-width: 475px;
}

.forgot-pssw-top {
  width: 99%;
  background-color: #fffdfd;
  padding-top: 20px;
  margin-bottom: 20px;
}

.forgot-pssw-top p {
  font-size: 20px;
  margin-bottom: -20px;
}

/* form */
.forgot-password-form-container {
  width: 475px;
  margin: 0 auto;
}

.forgot-password-form {
  background-color: #fffdfd;
  padding: 20px;

  /*box-shadow: 0px 2px 10px rgba(126, 2, 103, 0.2);*/
  /*border-radius: 10px;*/
}

.forgot-password-form p {
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}

/* Стилі для полів введення */
.forgot-password-form input[type="text"],
.forgot-password-form input[type="email"],
.forgot-password-form input[type="password"] {
  width: calc(100% - 22px); /*віднімаємо ширину внутрішнього відступу */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #fffdfd;
  border-radius: 5px;
  display: inline-block; /* розміщуємо поля поруч */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.forgot-password-form input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  outline: none;
  border-color: #347ae2;
}

.to-logit-btn {
  padding-left: 25px;
  padding-right: 25px;
  background-color: #347ae2;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.to-logit-btn:hover {
  background-color: #2050a6;
  box-shadow: 0px 0px 10px rgba(139, 27, 151, 0.1);
}

.to-logit-btn:focus {
  background-color: #860a94;
  box-shadow: none;
}

.forgot-pssw-bottom-p {
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
  color: black;
}
