/* for progress bars and emotions*/
.small-progress {
    background-color: #e0e0e0; /* Колір фону прогрес-бару */
}
  
.small-progress .progress-bar {
    background-color: #027BFF; /* Колір заповнення прогрес-бару */
}

.response-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    /*flex-grow: 1;*/
}



  /* Styling for the question unit container - more compact version */
.result-question-unit {
    background-color: #67a0f7; /* Light pink background color */
    padding: 10px 15px; /* Reduced padding */
    border-radius: 8px; /* Slightly smaller radius */
    margin-bottom: 10px; /* Reduced margin */
    /* box-shadow: 0px 0px 10px rgba(126, 2, 103, 0.1); Lighter shadow */
    width: 100%;
  }
  
  /* For text content - more compact */
  .result-question-unit p {
    font-size: 30px; /* Smaller font */
    line-height: 1.3; /* Tighter line height */
    color: #333;
    margin-bottom: 2px; /* Less space below */
  }
  


  