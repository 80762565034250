/* surveyPage.css */

.survey-page-main {
  max-width: 500px;
  width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);

  min-height: 92vh;
  margin-top: 40px;
  margin-bottom: 20px;

  color: #333;
}

.survey-page-top {
  max-width: 480px;
  width: 480px;

  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  overflow-x: auto;
  border-radius: 10px;
}

.survey-page-question-container {
  max-width: 480px;
  width: 480px;

  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  overflow-x: auto;
  border-radius: 10px;

  margin-top: 10px;
}

.question-image {
  max-width: 100%; /* This ensures the image is not bigger than its container */
  max-height: 400px; /* Set a max-height that suits your design */
  border: 2px solid #000; /* Example border, change as you like */
  border-radius: 4px; /* Optional: if you want rounded corners */
  object-fit: cover; /* This makes sure that the aspect ratio of the image is maintained */
}

/* used in Question.js */
.survey-page-question-unit {
  max-width: 470px;
  width: 470px;

  background: linear-gradient(
    to right,
    rgb(234, 212, 230, 0.1),
    rgba(126, 2, 103, 0.3),
    rgb(234, 212, 230, 0.1)
  );

  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 5px;

  padding-bottom: 15px;
  padding-top: 10px;
}

.emotion-slider {
  display: flex;
  align-items: center; /* Центрування по вертикалі */
  justify-content: space-between; /* Простір між текстом і слайдером */
  margin-bottom: 10px; /* Відступ між елементами */
}

.emotion-slider label {
  text-align: left;
  margin-left: 35px;
  margin-right: 10px; /* Відступ між текстом і слайдером */
  flex: 1; /* Забезпечує зайняття текстом всієї доступної ширини */
}

.emotion-slider input[type="range"] {
  flex: 1; /* Забезпечує зайняття слайдером всієї доступної ширини */
  max-width: 300px; /* Максимальна ширина слайдера, якщо потрібно */
  margin-right: 20px;
}

.styled-slider {
  -webkit-appearance: none; /* Вимикає стандартний вигляд слайдера в Safari */
  appearance: none; /* Вимикає стандартний вигляд слайдера */
  width: 100%;
  height: 10px;
  background: #347ae2; /* Колір фону */
  outline: none; /* Вимикає рамку при фокусуванні */
  opacity: 0.7;
  transition: opacity 0.2s;
  border-radius: 5px; /* Закруглені кути */
}

.styled-slider:hover {
  opacity: 1; /* Підвищення прозорості при наведенні */
}

.styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Вимикає стандартний вигляд повзунка в Safari */
  appearance: none; /* Вимикає стандартний вигляд повзунка */
  width: 20px;
  height: 20px;
  background: #7b057f; /* Колір повзунка */
  cursor: pointer;
  border-radius: 50%; /* Круглий повзунок */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Тінь повзунка */
}

.styled-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #347ae2;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
