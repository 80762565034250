/* CreateSyrveyPage.css - Merged with custom emotion styles */

.CreateSyrveyPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: "Times New Roman", Times, serif;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
  width: 600px;
}

.CreateSyrveyPage h1 {
  color: #333;
  margin-bottom: 10px;
}

.CreateSyrveyPage p {
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
}

/* syrvey logo (name, description) */
.syrvey-top-container {
  width: 550px;
  margin-top: 50px;
}

.syrvey-top {
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
}

.syrvey-top-text {
  color: black;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
}

/* Input field styles */
.syrvey-top input[type="text"] {
  padding: 10px;
  border: 1px solid #fffdfd;
  border-radius: 5px;
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: "Times New Roman", Times, serif;
}

.syrvey-top textarea {
  padding: 10px;
  border: 1px solid #fffdfd;
  border-radius: 5px;
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  min-width: 500px;
  min-height: 50px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: "Times New Roman", Times, serif;
}

.syrvey-top input[type="text"]:focus,
textarea:focus {
  outline: none;
  border-color: #347ae2;
}

/* SYRVEY QUESTIONS */
.syrvey-questions-container {
  width: 550px;
  margin-top: 20px;
}

.syrvey-questions {
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  padding-top: 1px;
  padding-bottom: 1px;
}

/* Question container styles */
.question-container {
  background-color: white;
  border-radius: 8px;
  margin: 15px;
  padding: 15px;
}

.question-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.question-type-select {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-family: "Times New Roman", Times, serif;
}

.remove-question-button {
  background-color: #dfdfdf;
  color: white;
  border: none;
  border-radius: 15%;
  width: 28px;
  height: 28px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; 
}
.remove-question-button:hover {
  background-color: #ff5252; /* This is a red color, you can change it to any color you prefer */
}


.question-input {
  width: 95%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 12px;
  resize: vertical;
  min-height: 60px;
  font-family: "Times New Roman", Times, serif;
}

/* Emotions section styles */
.emotions-section {
  margin-top: 12px;
}

.emotions-section p {
  margin-bottom: 8px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}

.emotions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.emotion-button {
  background-color: #ffffff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: "Times New Roman", Times, serif;
}
.emotion-button:hover {
  background-color: #ffffff;;
}

.emotion-button.selected {
  background-color: #347ae2;
  color: white;
}

.emotion-button.custom {
  background-color: #5c6bc0;
  color: white;
}

.add-emotion-button {
  background-color: #f0f0f0;
  color: #555;
  border: 1px dashed #999;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Times New Roman", Times, serif;
}

.add-emotion-button:hover {
  background-color: #e0e0e0;
}

.custom-emotion-input {
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
  align-items: center;
  gap: 8px;
}

.custom-emotion-input input {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  font-family: "Times New Roman", Times, serif;
}

.custom-emotion-input .add-button {
  background-color: #347ae2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-family: "Times New Roman", Times, serif;
}

.custom-emotion-input .add-button:hover {
  background-color: #2050a6;
}

/* Add question and submit button */
.add-question-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  font-family: "Times New Roman", Times, serif;
}

.add-question-button:hover {
  background-color: #e0e0e0;
}

.submit-survey-button {
  padding: 10px 20px;
  background-color: #347ae2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  font-family: "Times New Roman", Times, serif;
}

.submit-survey-button:hover {
  background-color: #2050a6;
  box-shadow: 0px 0px 10px rgba(139, 27, 151, 0.1);
}

.submit-survey-button:focus {
  background-color: #860a94;
  box-shadow: none;
}

.syrvey-add-submit-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding-bottom: 20px;
  padding-top: 20px;
}