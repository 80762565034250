/* EditSurvey.css */
.edit-survey-page {
  background-color: white; /* Змінює колір фону на білий */
  min-height: 100vh; /* Забезпечує покриття всієї висоти вікна браузера */
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 600px;
  width: 600px;
}

.edit-survey-container {
  max-width: 800px; /* Збільшили ширину контейнера */
  width: 100%; /* Забезпечує адаптивність */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.edit-survey-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.edit-survey-container label {
  display: block;
  margin: 10px 0 5px;
  color: #555;
}

.edit-survey-container input[type="text"],
.edit-survey-container select {
  width: 100%;
  padding: 10px;
  margin: 5px 0 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  max-width: 500px;
}

.edit-survey-container textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;

  max-width: 500px;
}

.edit-survey-container input[type="text"]:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #347ae2;
}

.edit-survey-container textarea {
  height: 100px;
}

.edit-survey-container button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 5px;
  background-color: #347ae2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.edit-survey-container button:hover {
  background-color: #2050a6;
}

.edit-survey-container .message {
  margin: 15px 0;
  color: #333;
}

.edit-survey-container .question-message {
  margin-top: 20px;
  color: #333;
}

.edit-survey-container .question-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
  border-radius: 10px;
}

.edit-survey-container .question-item label {
  margin: 5px 0;
}

.edit-survey-container .question-item input[type="text"],
.edit-survey-container .question-item textarea,
.edit-survey-container .question-item select {
  width: calc(100% - 20px);
  margin: 5px 10px 15px 0;
}

.edit-survey-container .question-item button {
  margin-top: 10px;
}

.edit-survey-container h2 {
  color: #333; /* Зміна кольору тексту */
  background-color: #f1f1f1; /* Додавання фону */
  padding: 10px; /* Додавання відступів */
  border-radius: 5px; /* Округлення кутів */
  text-align: center; /* Вирівнювання по центру */
}

.edit-survey-top {
  max-width: 580px;
  width: 580px;

  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
}
