/* LoggedInPage.css */

.LoggedInPage {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(233, 0, 0, 0.1);
}

.LoggedInPage h1,
.LoggedInPage p {
  color: #333;
}

.LoggedInPage img {
  max-width: 100px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

button {
  background-color: #347ae2; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #2050a6;
}

button:active {
  background-color: #347ae2; /*#860a94; */
  box-shadow: 0 5px #2050a6; /* #720a7d; */
  transform: translateY(2px);
}

/* logout button */
.logout-button {
  position: absolute; /* Absolute positioning for the button */
  top: -3px; /* Space from the top */
  right: 5px; /* Space from the right */
  background-color: #2050a6; /* Gray background */
  /* Other button styles */
}

.logout-button:hover {
  background-color: #0c518d; /* Darker gray */
}

.logout-button:active {
  background-color: #0c518d; /* Even darker gray */
  transform: translateY(0px);
  box-shadow: none;
}
